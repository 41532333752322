.Card{
    margin-left: 1rem;
     margin-right:1rem; 
     width: 20rem; 
     height: 44rem;
     border:0;
     text-align: left;
}
.Picture{
    height: 300px; 
    overflow: hidden; 
 
    background-color: white;
}
.Card2{
    width: 60rem;
    border:0;
    padding: 1rem;
    height: 43 rem;
    text-align: left;
}
.Progress{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.Info{
    margin-top: 3px;
}