.App{
    
  width: 100%; height: 100vh	;
  text-align: center;
}
.Login{
    margin: auto;
    max-width: 28rem;
    top: 30%;
    text-align: left;
}
.Header{
    text-align: center;
}
.Button{
    height: 100px;
}
.Link{
    text-decoration: none;
}
.sButton{
    float: right
}