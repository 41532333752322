.Searchbar{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 5%;
    
}
.App{
    width: 100%;
}
.Filter{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 2rem;
    text-align: left;
}
.insures{
    max-width: 100px;
    min-width: 80px;
}
.checkbox{
    text-align: center;
    margin: 5px;
}
.radio{
    margin-left: 10px;
}
.Link{
    color: black;
}
.sButton{
    float: right
}