.Picture{
    
  
      width: 100%;
      height: auto;
      overflow: hidden; 
      background-color: white;
      min-width: 120px;
      min-height: 120px;
  }
         
.App{
  background-color: #e4eced ;
  text-align: left;
  height: 400vh;
  width: 100%;
   }

.List{
    margin:20%;
    margin-top: 20px;
}
.ListAlign{
  height: auto;
}

.name{
    text-decoration: none;
    text-align: left;
}
.Search{
  margin: 20%;
  margin-bottom: 10px;
  margin-top: 5%
  
}
